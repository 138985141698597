<script lang='ts'>
	import { goto } from '$app/navigation';
	import { PATH } from '$lib/enums/path'; 
	import { authUser } from '$lib/stores/app'; 
	import { onMount } from 'svelte';
	// Check if the user is authenticated on component mount
	onMount(() => {
		console.log("AUTH USER LOGIN",$authUser);
		if ($authUser) { 
			goto(PATH.MY_PROFILE);
		}
	});

	 
</script>
<slot />